$yellow: #FDB924;
$light-green: #4DD69C;
$blue: #3340B6;
$light-blue: #339dff;

%yellow {
  background-color: $yellow;
  border: none;
}

%light-green {
  background-color: $light-green;
  border: none;
}

%blue {
  background-color: $blue;
  border: none;
}

%light-blue {
  background-color: $light-blue;
  border: none;
}

.yellow-btn {
  @extend %yellow;

  &:hover {
    @extend %yellow;
  }
}


.light-green-btn {
  @extend %light-green;

  &:hover {
    @extend %light-green;
  }
}

.blue-btn {
  @extend %blue;

  &:hover {
    @extend %blue;
  }
}

.light-blue-btn {
  @extend %light-blue;

  &:hover, &:active {
    @extend %light-blue;
  }
}

.icon-btn {
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 2px;
  padding: 0;
  font-size: 10px;
}

.ant-btn {
  display: inline-flex;
  justify-content: center;
}
