.ant-dropdown {
  .ant-dropdown-menu {
    padding: 0;
  }

  .ant-dropdown-menu-item {
    border-bottom: 1px solid #F1F1F1;
    height: 36px;

    .ant-dropdown-menu-title-content {
      a {
        text-transform: capitalize;
        color: $text-secondary-color;

        &:hover {
          color: $primary-color;
        }
      }

      i.fa-solid {
        width: 24px;
        padding-right: 4px;
        justify-content: center;
      }
    }
  }
}
