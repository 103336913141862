.message-container {
  &__filters {
    .ant-card {
      border-radius: 6px;
      -moz-border-radius: 6px;
      -webkit-border-radius: 6px;
      background-color: #FFFFFF;

      .ant-card-head {
        padding: 0 12px;
        border-bottom: 1px solid transparent;

        &:hover {
          cursor: pointer;
        }

        .ant-card-head-title {
          padding: 12px 0;

          & div {
            display: flex;
            justify-content: space-between;
          }
        }
      }

      .ant-card-body {
        padding: 6px 12px 12px 12px;
      }
    }

    &-collapsed {
      .ant-card .ant-card-body {
        display: none;
      }
    }

    &-expanded {
      .ant-card .ant-card-body {
        display: block;
      }
    }

    &-form {
      .ant-form-item-label {
        flex-grow: 0;
        margin-right: 0;

        @media screen and(min-width: 516px) {
          flex-basis: 100px;
        }

        label {
          font-size: 14px;
          font-weight: 400;
          font-family: Roboto, sans-serif;
        }
      }

      .form-input {
        border-radius: 4px;
        -moz-border-radius: 4px;
        -webkit-border-radius: 4px;
        border: 1px solid #C0C0C0;
      }
    }
  }

  &__data-list {
    border-radius: 6px;
    -moz-border-radius: 6px;
    -webkit-border-radius: 6px;
    padding: 0 12px 12px 12px;

    &-title {
      //background-color: #00ABF3;
      display: flex;
      align-items: center;
      padding: 12px 0;
      font-size: 16px;
      font-weight: 500;
    }

    &-action-col {
      //display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-end;
      display: none;
      @media screen and (max-width: 1023px){
        display: block;
      }
    }

    &__action {
      width: 24px;
      height: 24px;
      padding: 6px;
      margin-bottom: 4px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #FFFFFF;
      border-radius: 4px;
      -moz-border-radius: 4px;
      -webkit-border-radius: 4px;
      cursor: pointer;
    }
    .message-table-row {
      .data {
        display: flex;
        flex-direction: column;
        justify-content: center;
        min-height: 125px;
      }
      &-item {
        display: flex;
        justify-content: space-between;
      }
    }

    .message-table-row-item > span > i {
      color:#7D7E7E;
      margin-right: 3px;
    }

    .message-table-row:hover td {
      background: #e5fce5 !important;
    }

    .message-table-row:hover .message-container__data-list-action-col {
      display: block;
    }
  }
}
