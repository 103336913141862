.payment-reason-container {
  font-family: Roboto, sans-serif;

  &__filter {
    padding: 12px;

    &-title {
      display: flex;
      justify-content: space-between;
      font-size: 16px;
      font-weight: 500;
      cursor: pointer;
    }

    &-search {
      margin-top: 12px;
      .ant-form {
        .ant-form-item {
          &-label {
            //flex-basis: 30px;
          }
        }
      }
    }
  }

  &__data, &__create-box {
    padding: 12px;
    border-radius: 4px;
    -moz-border-radius: 4px;
    -webkit-border-radius: 4px;
    box-shadow: 0 0 6px rgba(0, 0, 0, 0.05), 0 7px 24px rgba(17, 17, 17, 0.08);
    background-color: #FFFFFF;
  }

  &__data {
    margin:0 0 12px 12px;
    @media screen and (max-width: 991px) {
      margin-right: 12px;
    }

    &-header {
      margin-bottom: 12px;
      font-size: 16px;
      font-weight: 500;
    }

    &-list {
      .ant-table {
        .ant-table-thead {
          font-family: Roboto, sans-serif;
          font-weight: 500;

          > tr {
            > th {
              padding: 6px 12px !important;
              background-color: #F6F6F6;
            }
          }
        }

        .ant-table-tbody {
          > tr {
            > td {
              padding: 8px 12px;
              font-family: Roboto, sans-serif;
              font-weight: 400;
              font-size: 14px;
            }
          }
        }

        .payment-reason-record:hover td {
          background: #e5fce5 !important;
        }

        ::-webkit-scrollbar {
          width: 6px;
        }

        /* Track */
        ::-webkit-scrollbar-track {
          box-shadow: inset 0 0 24px #e5fce5;
          border-radius: 10px;
        }

        /* Handle */
        ::-webkit-scrollbar-thumb {
          background-color: transparentize(#191919,0.8);
          border: 5px solid rgba(25, 25, 25, 0.2);
          border-radius: 16px;
        }

        /* Handle on hover */
        ::-webkit-scrollbar-thumb:hover {
          background: #707070;
        }
      }
    }
  }

  &__create-box {
    margin:0 12px 12px 12px;

    .ant-form {

      .ant-form-item {
        &-control {
          @media screen and(min-width: 1025px) {
            padding-left: 8px;
          }
        }
        &-extra {
          padding-top: 8px;
        }
        &-label {
          label.ant-form-item-required:not(.ant-form-item-required-mark-optional)::before {
            @media screen and(width: 1024px) and (min-width:426px) {
              position: absolute;
              right: -20px;
              top: 6px;
              margin-right: 12px;
            }
            @media screen and (width: 768px) {
              margin-right:0;
              right: -5px;
            }
          }
        }
      }
    }
    .form-buttons {
      display: flex;
      justify-content: flex-end;
    }
  }

}
