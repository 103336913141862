.transaction-quickView {
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    margin: 4px 0;
    padding: 4px;
    > div {
      flex-direction: column;
      align-items: flex-start;
      margin: 2px 0;
    }
    .date-picker {
      width: 100%;
      margin: 6px 0;
    }
  }
}

.sub-transaction-form {
  .ant-modal-header {
    border: none;
  }

  .ant-modal-body {
    padding: 0 12px!important;
  }

  .ant-card {
    border:1px solid transparent;
    background-color: #F7F7F7;
    margin-bottom: 12px;
    border-radius: 4px;
    -webkit-border-radius: 4px;

    .ant-card-head {
      padding: 6px 12px;
      border-bottom: 1px solid transparent;

      .ant-card-head-title {
        padding: 0;
        color:$primary-color;
        font-size:16px;
      }
      .ant-card-extra {
        padding: 0;
        > button, >button:disabled {
          padding: 0;
          background-color: transparent;
          border:1px solid transparent;

          &:not(:disabled) {
            color: #FF4559;
          }
        }
      }
    }
    .ant-card-body {
      padding: 0 12px;
    }
  }

  .ant-modal-footer .ant-btn + .ant-btn:not(.ant-dropdown-trigger) {
    margin-bottom: 0;
    margin-left: 0;
  }
  .ant-modal-footer {
    border: none;
  }
}

.sub-transactions-box {
  .ant-table {
    border-radius: 4px 4px 0 0;

    .ant-table-thead > tr > th {
      background: #EBEBEB;
      color: #1a1a1a;
      padding: 6px 12px;
      white-space: nowrap;
      font-size:12px;
      font-weight: 500;

      @media screen and (min-width: 992px) and (max-width: 1281px) {
        padding: 5px 8px;
      }
    }

    .ant-table-tbody > tr > td {
      padding: 16px 12px;
      white-space: nowrap;

      @media screen and (min-width: 992px) and (max-width: 1281px) {
        padding: 8px 6px;
      }
    }

  }
}
.active-row-key {
  background-color: #F7F7F7;
  > td ,.ant-table-cell {
    background-color: #F7F7F7;
  }
  > td {
    padding: 0 12px 12px 12px;
  }

  .sub-transactions-box {
    .ant-table {
      .ant-table-tbody > tr > .ant-table-cell {
        background-color: #FFFFFF;
      }
    }
  }
}
