@import '../../../resources/scss/variables/variable';
@import '../../../resources/scss/mixins/mixins';

.transaction-history-page {
  .search-form {
    padding: 12px;

    .title {
      display: flex;
      justify-content: space-between;
      align-items: center;

      button {
        color: $text-dark-color;
      }
    }

    .ant-form-item {
      flex-wrap: nowrap;
    }

    .ant-form-item-label {
      min-width: 95px;

      @include lt-lg {
        min-width: 140px;
      }
    }

    .min-width-label-medium {
      .ant-form-item-label {
        min-width: 115px;

        @include lt-lg {
          min-width: 140px;
        }
      }
    }

    .min-width-label-large {
      .ant-form-item-label {
        min-width: 130px;

        @include lt-lg {
          min-width: 140px;
        }
      }
    }

    .min-width-label-auto {
      .ant-form-item-label {
        min-width: unset;
        @include lt-lg {
          min-width: unset;
        }
      }
    }

    @media screen and (max-width: 1199px) {
      .min-width-label-medium,
      .min-width-label-large {
        .ant-form-item-label {
          min-width: 130px;
        }
      }
    }

    @include xs {
      .ant-form .ant-form-item .ant-form-item-label {
        flex: unset;
      }

      .ant-form .ant-form-item .ant-form-item-control {
        flex: 1;
      }

      .ant-form-item-label > label::after {
        content: ':';
        position: relative;
        top: -0.5px;
        margin: 0 8px 0 2px;
        display: block;
      }
    }

    .ant-input-group-compact {
      .ant-select-single {
        width: 30% !important;
      }
    }

    .people-and-status {

      @include lt-lg {
        flex-direction: column;

        .people-item {
          padding-right: 0;
        }

        .status-item {
          .ant-form-item-label {
            min-width: 140px;
          }
        }
      }
    }

    .item-overflow-hidden {
      .ant-form-item-control {
        overflow: hidden;
      }
    }
  }

  .actions {
    width: 24px;
    height: 100%;
    opacity: 0;
  }

  .table-container {
    @media screen and (max-width: 1280px) {
      .ant-table {
        .ant-table-thead > tr > td {
          padding: 2px 4px;
        }
      }
    }

    .ant-table-row {
      &:hover {
        cursor: pointer;

        .actions {
          opacity: 1;
          transition: all 0.3s;
        }
      }
    }

    .participant {
      min-width: 60%;
      max-width: 60%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }


  }
}
