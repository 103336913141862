.a-table {
    &--responsive {
        width: 100%;
        overflow: auto;
    }
    &--font-small {
        table tbody {
            font-size: 12px;
        }
    }
}

.large-height-row {
    .ant-table-tbody {
        > tr {
            > td {
                height: calc(68px + 12px);
            }
        }
    }
}

.ant-table {
    .ant-table-thead > tr > th {
        border-bottom: none;
        background: #f6f6f6;
        color: #1a1a1a;
        padding: 5px 12px;
        white-space: nowrap;

        @media screen and (min-width: 992px) and (max-width: 1281px) {
            padding: 4px 4px;
        }
    }

    .ant-table-container {
        table {
            > thead {
                > tr:first-child {
                    th:first-child {
                        border-top-left-radius: 4px;
                    }

                    th:last-child {
                        border-top-right-radius: 4px;
                    }
                }
            }
        }
    }

    .ant-table-thead > tr > th:not(:last-child):not(.ant-table-selection-column):not(.ant-table-row-expand-icon-cell):not([colspan])::before {
        display: none;
    }

    .ant-table-tbody {
        > tr {
            > td {
                border-color: #f1f1f1;
                padding: 6px 12px;

                &.ant-table-cell-row-hover {
                    background: #e5fce5;
                }

                @media screen and (min-width: 992px) and (max-width: 1281px) {
                    padding: 4px 4px;
                }
            }
            .ant-table-row-expand-icon-cell {
                >span {
                    @media screen and (max-width: 1281px) {
                        font-size: 1px;
                        > div {
                            width: 8px;
                        }
                    }
                }

                > span > div {
                    width: 12px;
                    color: $primary-color;
                    padding:0 0 65px 0;
                }
                > div > i {
                    font-weight: 900;
                }
            }
        }
    }
    .ant-table-tbody > tr:last-child {
        > td {
            border-bottom: 1px solid transparent;
        }
    }

    /* total width */
    ::-webkit-scrollbar {
        width: 4px;
    }

    /* background of the scrollbar except button or resizer */
    ::-webkit-scrollbar-track {
        background-color: #fff;
    }

    /* scrollbar itself */
    ::-webkit-scrollbar-thumb {
        border-radius: 16px;
        background-color: transparentize(#191919, 0.8);
        border: 6px solid rgba(25, 25, 25, 0.2);
    }

    /* set button(top and bottom of the scrollbar) */
    ::-webkit-scrollbar-button {
        display: none;
    }
}

.ant-table-pagination.ant-pagination {
    margin: 12px 0 0 0;
}

.table-container {
    @include lt-md {
        .table-header {
            flex-direction: column;

            button {
                margin-top: 8px;
            }
        }
    }
}
