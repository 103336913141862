.log-modal {
  .ant-modal-body {
    overflow: hidden !important;
    height: calc(85vh - 53px - 47px);
    padding-right: 0 !important;

    .ant-list {
      height: calc(100% - 32px - 12px);
      overflow: auto;
    }
  }
}

.hidden-modal {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 300px;
  height: 46px;
  background: #FFF;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 12px;
  font-weight: 500;
  z-index: 16;
  box-shadow: 0 3px 6px -4px rgb(0 0 0 / 12%), 0 6px 16px 0 rgb(0 0 0 / 8%), 0 9px 28px 8px rgb(0 0 0 / 5%);
  border-radius: 8px 8px 0 0;
  cursor: pointer;
  font-size: 16px;
  text-transform: capitalize;
}
